<!-- 会员管理 -->
<template>
  <div>
    <div class="mainBody">
      <!--导入导出Excel-->
      <div class="importExport">
        <el-button
          style="margin: 10px 20px 10px 10px;"
          @click="uploadDialog = true"
          :disabled="myRight<2"
        >{{ $t("UploadData") }}Excel
        </el-button>
        <download-excel
          class="export-excel-wrapper"
          :data="DetailsForm"
          style="float: left; margin-left: 10px"
          :fields="json_fields"
          :header="title"
          type="xlsx"
          :name="$t('UserManagement')+'.xls'"
        >
          <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
          <el-button
            style="margin: 10px 0; "
            :disabled="myRight<2"
          >{{ $t("ExportExcelData") }}Excel</el-button>
        </download-excel>
        <el-button
          style="float:right; margin: 10px 20px;"
          @click="addPersonDialog = true"
          :disabled="myRight<2"
        >{{ $t("AddPerson") }}
        </el-button>
      </div>
      <!--搜索框-->
      <div class="filterBarUser">
        <!--公司名称-->
        <div>
          <label for="businessName">{{ $t("CompanyName") }}：</label>
          <input
            type="text"
            id="businessName"
            v-model.trim="companyName"
          >
        </div>
        <!--联系人-->
        <div>
          <label for="contactName">{{ $t("RealName") }}：</label>
          <input
            type="text"
            id="contactName"
            v-model.trim="realName"
          >
        </div>
        <!--Email-->
        <div>
          <label for="email">Email：</label>
          <input
            id="email"
            v-model.trim="email"
          >
        </div>
        <!--联系电话-->
        <div>
          <label for="contactNumber">{{ $t("ContactNum") }}：</label>
          <input
            id="contactNumber"
            v-model.number.trim="contactNumber"
            type="number"
          >
        </div>
        <!--公司地址-->
        <div>
          <label for="address">{{ $t("CompanyAddress") }}：</label>
          <input
            type="text"
            id="address"
            v-model.trim="address"
          >
        </div>
        <el-button @click="searchUserData">{{ $t('Search') }}</el-button>
      </div>

      <!--二级导航栏 全部/启用/禁用/异常-->
      <div class="navigatorUser">
        <span
          id="all"
          style="border-right:none"
          @click="active(1)"
          :class="{'active':activeUser===1}"
        >{{ $t("AllData") }}</span>
        <span
          id="start"
          style="border-right:none"
          @click="active(2)"
          :class="{'active':activeUser===2}"
        >{{ $t("Initiate") }}</span>
        <span
          id="banned"
          style="border-right:none"
          @click="active(3)"
          :class="{'active':activeUser===3}"
        >{{ $t("Banned") }}</span>
        <span
          id="banned"
          @click="active(7)"
          :class="{'active':activeUser===7}"
        >{{ $t("Freeze") }}</span>
      </div>

      <!--表格-->
      <el-table
        :data="userList"
        border
        style="width: 99%;margin: 0 auto"
      >
        <!--公司名称-->
        <el-table-column
          prop="companyName"
          :label="$t('CompanyName')"
        >
        </el-table-column>
        <!--昵称-->
        <!--        <el-table-column
          prop="nickName"
          :label="$t('NickName')"
        >
        </el-table-column>-->
        <!--姓名-->
        <el-table-column
          prop="realName"
          :label="$t('RealName')"
        >
        </el-table-column>
        <!--分组-->
        <el-table-column
          prop="groupName"
          :label="$t('GroupType')"
        >
        </el-table-column>
        <!--Email-->
        <el-table-column
          prop="email"
          label="Email"
        >
        </el-table-column>
        <!--联系电话-->
        <el-table-column
          prop="contactNumber"
          width="150"
          :label="$t('ContactNum')"
        >
        </el-table-column>
        <!--地址-->
        <el-table-column
          prop="address"
          :label="$t('CompanyAddress')"
        >
        </el-table-column>
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          width="165px"
          :label="$t('CreateTime')"
        >
        </el-table-column>
        <!--状态-->
        <el-table-column
          width="80"
          :label="$t('Status')"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isFreeze === 0">{{ numToStatus(scope.row.status) }}</span>
            <span v-else>{{ $t('Freeze') }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          width="210"
          :label="$t('Operation')"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="initiateUser(scope.row)"
              :disabled="myRight === 1 || scope.row.status === 1"
            >{{ $t("Initiate") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="banUser(scope.row)"
              :disabled="myRight === 1 || scope.row.status === 2"
            >{{ $t("Banned") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="editUserDialog(scope.row,'editPerson');editPersonDialog = true;"
              :disabled="myRight === 1 "
            >{{ $t("Edit") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="deleteUserData(scope.row)"
              style="color: red;"
              :disabled="myRight !== 3"
            >{{ $t("DeleteData") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="userTotal"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>

    <!--上传Excel弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('UploadData')+' EXCEL'"
      :visible.sync="uploadDialog"
      :before-close="handleClose"
      :show-close="false"
      append-to-body
      center
    >
      <el-form
        :model="uploadDataForm"
        ref="uploadForm"
        :rules="rules"
        v-if="uploadDialog"
        class="uploadExcel"
      >
        <!--初始密码-->
        <el-form-item
          :label="$t('InitialPassword')"
          prop="password"
          :label-width="uploadDataForm.formLabelWidth"
        >
          <el-input
            v-model="uploadDataForm.password"
            show-password
            maxlength="16"
            @input="uploadDataForm.password = uploadDataForm.password.replace(/[^\a-\z\A-\Z0-9\!\@\#\$\%\^\&\*\_]/g, '')"
          ></el-input>
        </el-form-item>
        <!--确认密码-->
        <el-form-item
          :label="$t('InputPassword2')"
          prop="checkPassword"
          :label-width="uploadDataForm.formLabelWidth"
        >
          <el-input
            v-model="uploadDataForm.checkPassword"
            show-password
            maxlength="16"
            @input="uploadDataForm.checkPassword = uploadDataForm.checkPassword.replace(/[^\a-\z\A-\Z0-9\!\@\#\$\%\^\&\*\_]/g, '')"
          ></el-input>
        </el-form-item>
        <!--拖拽上传-->
        <el-form-item
          :label="$t('UploadData')+$t('File')"
          :label-width="uploadDataForm.formLabelWidth"
        >
          <div class="import">
            <el-upload
              drag
              class="upload-demo"
              action
              :auto-upload="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :on-change="beforeUpload"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :file-list="uploadDataForm.fileList"
              :limit="1"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">{{$t('DragUpload')}}<em>{{$t('ClickUpload')}}</em></div>
              <div
                class="el-upload__tip"
                slot="tip"
              >{{$t('XlsOnly')}}</div>
            </el-upload>
            <div>
              <el-button><a href="https://otc-ydn.oss-cn-hangzhou.aliyuncs.com/saas202302091802229tFnGSkd5G.xlsx">{{$t('DownloadModel')}}</a>
              </el-button>
            </div>
          </div>
        </el-form-item>
        <!--短信验证文本框-->
        <el-form-item
          :label="$t('InfoMsg')"
          prop="messageText"
          :label-width="uploadDataForm.formLabelWidth"
        >
          <el-input
            type="textarea"
            :rows="5"
            resize="none"
            v-model="uploadDataForm.messageText"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        v-if="uploadDialog"
      >
        <el-button @click="cancelExcel">{{ $t("Cancle") }}</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="submitUpload('uploadForm')"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>

    <!--添加用户弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('AddPerson')"
      :visible.sync="addPersonDialog"
      append-to-body
      center
      :show-close="false"
    >
      <el-form
        :model="addPersonForm"
        ref="addPerson"
        :rules="rules"
        v-if="addPersonDialog"
      >
        <!--公司名称-->
        <el-form-item
          :label="$t('CompanyName')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="addPersonForm.companyName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--联系人-->
        <el-form-item
          :label="$t('RealName')"
          :label-width="formLabelWidth"
          prop="realname"
        >
          <el-input
            v-model="addPersonForm.realname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--Email-->
        <el-form-item
          label="Email"
          prop="email"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="addPersonForm.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--联系电话-->
        <el-form-item
          :label="$t('ContactNum')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.number.trim="addPersonForm.contactNumber"
            autocomplete="off"
            type="number"
          ></el-input>
        </el-form-item>
        <!--公司地址-->
        <el-form-item
          :label="$t('CompanyAddress')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="addPersonForm.address"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--初始密码-->
        <el-form-item
          :label="$t('InitialPassword')"
          :label-width="formLabelWidth"
          prop="pass"
        >
          <el-input
            v-model="addPersonForm.pass"
            show-password
            maxlength="16"
            @input="addPersonForm.pass = addPersonForm.pass.replace(/[^\a-\z\A-\Z0-9\!\@\#\$\%\^\&\*\_]/g, '')"
          ></el-input>
        </el-form-item>
        <!--确认初始密码-->
        <el-form-item
          :label="$t('InputPassword2')"
          :label-width="formLabelWidth"
          prop="checkPass"
        >
          <el-input
            v-model="addPersonForm.checkPass"
            show-password
            maxlength="16"
            @input="addPersonForm.checkPass = addPersonForm.checkPass.replace(/[^\a-\z\A-\Z0-9\!\@\#\$\%\^\&\*\_]/g, '')"
          ></el-input>
        </el-form-item>
        <!--文本框-->
        <el-form-item
          :label="$t('InfoMsg')"
          prop="info"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="addPersonForm.info"
            :rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        v-if="addPersonDialog"
      >
        <!--<el-button @click="addPersonDialog = false;$refs['addPerson'].resetFields()">{{ $t("Cancle") }}</el-button>-->
        <el-button @click="clearAddPerson">{{ $t("Cancle") }}</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="addPersonData('addPerson')"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>

    <!--编辑用户弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('EditPerson')"
      :visible.sync="editPersonDialog"
      append-to-body
      center
      :show-close="false"
    >
      <el-form
        :model="editPersonForm"
        ref="editPerson"
        :rules="rules"
      >
        <!--公司名称-->
        <el-form-item
          :label="$t('CompanyName')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="editPersonForm.companyName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--昵称-->
        <!--        <el-form-item
          :label="$t('NickName')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="editPersonForm.nickName"
            autocomplete="off"
          ></el-input>
        </el-form-item>-->
        <!--真实姓名-->
        <el-form-item
          :label="$t('RealName')"
          :label-width="formLabelWidth"
          prop="realName"
        >
          <el-input
            v-model="editPersonForm.realName"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <!--Email-->
        <el-form-item
          label="Email"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.number="editPersonForm.email"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <!--联系电话-->
        <el-form-item
          :label="$t('ContactNum')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.number="editPersonForm.contactNumber"
            autocomplete="off"
            type="number"
            disabled
          ></el-input>
        </el-form-item>
        <!--公司地址-->
        <el-form-item
          :label="$t('CompanyAddress')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="editPersonForm.address"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editPersonDialog = false;$refs['editPerson'].resetFields()">{{ $t("Cancle") }}</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="editUserData('editPerson')"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/fetch/api";

import mixin from "@/assets/js/mixin";

export default {
  mixins: [mixin],
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;

    //            添加用户
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("EmptyPassword")));
      } else {
        if (this.addPersonForm.passWord2 !== "") {
          this.$refs.addPerson.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("EmptyPassword2")));
      } else if (value !== this.addPersonForm.pass) {
        callback(new Error(this.$t("UnpairPassword")));
      } else {
        callback();
      }
    };
    //            邮箱验证
    const validateEmail = (rule, value, callback) => {
      if (value !== "") {
        if (value.indexOf("@") === -1) {
          callback(new Error(this.$t("ValidEmail")));
        }
      } else {
        callback(new Error(this.$t("EmptyEmail")));
      }
      callback();
    };

    //            上传EXCEL
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("EmptyPassword")));
      } else {
        if (this.uploadDataForm.checkPassword !== "") {
          this.$refs.uploadForm.validateField("checkPassword");
        }
        callback();
      }
    };
    var validatePass4 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("EmptyPassword2")));
      } else if (value !== this.uploadDataForm.password) {
        callback(new Error(this.$t("UnpairPassword")));
      } else {
        callback();
      }
    };
    return {
      myRight: data.userPermission,
      uploadDialog: false,
      addPersonDialog: false,
      editPersonDialog: false,
      activeUser: 1,
      chamberId: data.chamberId,
      tenantCode: data.tenantCode,
      adminId: data.id,
      userList: [],
      userTotal: 0,
      pageSize: 15,
      currentPage: 1,
      groupId: [],

      // 搜索用
      companyName: "",
      realName: "",
      email: "",
      contactNumber: "",
      address: "",

      messageText: "",
      formLabelWidth: "200px",

      //                导出EXCEL
      title: this.$t("UserManagement"),
      exportList: [],
      json_fields: {},
      /*json_fields: {
        公司名称: "companyName",
        联系人: {
          field: "contactName",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        联系电话: "contactNumber",
        公司地址: "address",
        创建时间: "createTime",
        状态: "status",
      },*/
      DetailsForm: [],
      detailObj: {},

      initUser: {
        chamberId: data.chamberId,
        page: 1,
        adminId: data.id,
        currentAdmin: data.id,
      },
      initUserExcel: {
        chamberId: data.chamberId,
        page: 1,
        isExcelNeed: 1,
        adminId: data.id,
        currentAdmin: data.id,
      },

      loading: false,

      addPersonForm: {
        companyName: "",
        realname: "", // 为了区分开编辑用户里的realName
        email: "",
        contactNumber: "",
        address: "",
        password: "",
        pass: "",
        checkPass: "",
        adminId: data.id,
        chamberId: data.chamberId,
        messageText: "",
        currentAdmin: data.id,
      },

      editPersonForm: {
        companyName: "",
        nickName: "",
        realName: "",
        contactNumber: "",
        address: "",
        status: 0,
        userId: 0,
        messageText: "",
        adminId: data.id,
        currentAdmin: data.id,
      },

      uploadDataForm: {
        password: "",
        checkPassword: "",
        fileList: [],
        formLabelWidth: "200px",
        messageText: "",
        currentAdmin: data.id,
      },
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        realName: [
          { required: true, message: this.$t("EmptyName"), trigger: "blur" },
        ],
        realname: [
          { required: true, message: this.$t("EmptyName"), trigger: "blur" },
        ],
        contactNumber: [{ message: this.$t("FillTel"), trigger: "blur" }],
        pass: [
          { required: true, validator: validatePass, trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: this.$t("PasswordLengthTips"),
            trigger: "blur",
          },
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: this.$t("PasswordLengthTips"),
            trigger: "blur",
          },
        ],
        messageText: [
          { required: true, message: this.$t("EmptyInfo"), trigger: "blur" },
        ],
        info: [
          { required: true, message: this.$t("EmptyInfo"), trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePass3, trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: this.$t("PasswordLengthTips"),
            trigger: "blur",
          },
        ],
        checkPassword: [
          { required: true, validator: validatePass4, trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: this.$t("PasswordLengthTips"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    /*uploadDataForm: {
      deep: true,
      handler(val) {
        console.log("Watch fileList", val.fileList);
      },
    },*/
  },
  mounted() {
    this.initUserData(1);
    this.forExport();
    this.json_fields[this.$t("CompanyName")] = "companyName";
    this.json_fields[this.$t("NickName")] = {
      field: "nickName",
      callback: (value) => {
        return "&nbsp;" + value;
      },
    };
    this.json_fields[this.$t("RealName")] = "realName";
    this.json_fields["Email"] = "email";
    this.json_fields[this.$t("ContactNum")] = "contactNumber";
    this.json_fields[this.$t("CompanyAddress")] = "address";
    this.json_fields[this.$t("CreateTime")] = "createTime";
    this.json_fields[this.$t("Status")] = "status";
    this.getGroups();
  },
  methods: {
    // 1 启用 2 禁用 3 异常 4 未审核 5 审核中
    active(num) {
      this.activeUser = num;
      this.initUserData(1);
    },

    handleClose(done) {
      done();
      this.uploadDataForm.fileList = [];
    },

    //            弹窗：取消
    cancle() {
      this.addPersonDialog = false;
      this.addPersonForm = {
        companyName: "",
        contactName: "",
        contactNumber: "",
        companyAddress: "",
        passWord: "",
        messageText: "",
      };
    },

    //            初始化用户列表
    initUserData(currentPage) {
      this.currentPage = currentPage;
      this.initUser.page = currentPage;
      this.activeUser === 1
        ? delete this.initUser.status
        : (this.initUser.status = this.activeUser - 1);
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      this.initUser.language = languageObj[locale];
      api.getUerList(this.initUser).then((res) => {
        this.userList = res.data.userList;
        this.userTotal = res.data.total;
      });
    },

    //            导出用接口
    forExport() {
      this.DetailsForm = [];
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      this.initUserExcel.language = languageObj[locale];
      api.getUerList(this.initUserExcel).then((res) => {
        this.exportList = res.data;
        //                    导出用
        if (this.exportList !== null && this.exportList !== []) {
          this.exportList.forEach((obj) => {
            this.detailObj = {};
            for (let key in obj) {
              if (key === "status") {
                this.detailObj[key] = this.numToStatus(obj[key]);
              } else {
                this.detailObj[key] = obj[key];
              }
              //                            console.log('detailObj',this.detailObj)
            }
            this.DetailsForm.push(this.detailObj);
          });
        } else {
          this.DetailsForm = [];
        }
        delete this.initUser.isExcelNeed;
      });
    },

    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initUserData(currentPage);
    },

    //            --------------------------------------------------上传EXCEL-----------------------------------------------

    //            上传EXCEL
    submitUpload(formName) {
      if (this.uploadDataForm.fileList.length === 0) {
        this.$alert(this.$t("SelectFile"));
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = new FormData();
            param.append("password", this.uploadDataForm.password);
            param.append("chamberId", this.chamberId);
            param.append("file", this.uploadDataForm.files.raw);
            param.append("currentAdmin", this.adminId);
            param.append("messageText", this.uploadDataForm.messageText);
            this.loading = true;
            api
              .uploadMembers(param)
              .then(
                (res) => {
                  // console.log("请求成功了", response.data);
                  if (res.data.msg === "有相同邮箱，上传失败") {
                    // console.log('有相同邮箱，上传失败')
                    this.$message.error(this.$t("EmailExist"));
                  }
                  this.uploadDataForm.fileList = [];
                  this.uploadDataForm.password = "";
                  // console.log("传的参数", this.uploadDataForm.files);
                  this.uploadDialog = false;
                  this.initUserData(1);
                  api
                    .scanNewMember({
                      systemType: 1, // 1:商会、2:律所
                      chamberId: this.chamberId, // 商会id
                      tenantCode: this.tenantCode,
                    })
                    .then(() => {
                      this.$message.success(this.$t("AddSuccess"));
                    });
                },
                (error) => {
                  this.$message.error(this.$t("SysBusy"));
                  // console.log("请求失败了", error.message);
                }
              )
              .finally(() => {
                this.loading = false;
              });
          } else {
            console.log("error submit");
            return false;
          }
        });
      }
    },

    //            检测上传文件格式
    beforeUpload(file) {
      this.uploadDataForm.files = "";
      this.uploadDataForm.fileList = [];
      // 上传前让用户再次确认Excel模板
      this.$confirm(this.$t("ConfirmExcel"), this.$t("Attention"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancle"),
        type: "warning",
      })
        .then(() => {
          /* debugger */
          this.uploadDataForm.files = file;
          //                console.log('file',file,this.uploadDataForm.files)
          //	const extension = file.name.split('.')[1] === 'xls'
          const extension2 = file.name.split(".")[1] === "xlsx" || "xls";
          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!extension2) {
            this.$message.warning(this.$t("XlsOnly"));
            return;
          }
          if (!isLt5M) {
            this.$message.warning(this.$t("M5BOnly"));
            return;
          }
          this.uploadDataForm.fileList.unshift(file);
          console.log(
            "this.uploadDataForm.files ->",
            this.uploadDataForm.files
          );
          this.$message.success(this.$t("UploadSuccess"));
          return false; // 返回false不会自动上传
        })
        .catch(() => {
          this.uploadDataForm.files = "";
          this.uploadDataForm.fileList = [];
          this.$message.info(this.$t("UploadCancel"));
          return false;
        });
    },

    //            如果上传多个文件（限单文件）
    handleExceed() {
      this.$message.error(this.$t("OneFileOnly"));
    },

    //            删除上传文件
    handleRemove(file, fileList) {
      // console.log("remove file", file, fileList);
      let index = this.uploadDataForm.fileList.indexOf(file);
      // console.log("index", index);
      this.uploadDataForm.fileList.splice(index, 1);
    },

    cancelExcel() {
      this.uploadDataForm = {
        password: "",
        checkPassword: "",
        formLabelWidth: "200px",
        fileList: [],
        messageText: "",
        currentAdmin: this.adminId,
      };
      this.uploadDialog = false;
    },

    //            -------------------------------------------------增删改查用户-----------------------------------------------

    //            搜索用户列表
    searchUserData() {
      this.companyName
        ? (this.initUser.companyName = this.companyName)
        : delete this.initUser.companyName;
      this.nickName
        ? (this.initUser.nickName = this.nickName)
        : delete this.initUser.nickName;
      this.realName
        ? (this.initUser.contactName = this.realName)
        : delete this.initUser.contactName;
      this.email
        ? (this.initUser.email = this.email)
        : delete this.initUser.email;
      this.contactNumber
        ? (this.initUser.contactNumber = this.contactNumber)
        : delete this.initUser.contactNumber;
      this.address
        ? (this.initUser.address = this.address)
        : delete this.initUser.address;
      // console.log("api调用", this.initUser);
      this.initUserData(1);
    },

    // 获取会员组别
    getGroups() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      let param = {
        pageNum: 1,
        pageSize: 4,
        language: languageObj[locale],
        chamberId: this.chamberId,
      };
      api.getGroupByChamberId(param).then((res) => {
        this.groupId = res.data.data.records[0].groupId;
        let groups = res.data.data.records;
        groups.forEach((i) => {
          if (i.unable === 1) {
            this.groupId = i.groupId;
          }
        });
      });
    },

    //添加用户
    addPersonData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit");
          this.addPersonForm.password = this.addPersonForm.checkPass;
          this.addPersonForm.messageText = this.addPersonForm.info;
          this.addPersonForm.realName = this.addPersonForm.realname;
          if (!this.addPersonForm.companyName)
            delete this.addPersonForm.companyName;
          if (!this.addPersonForm.contactNumber)
            delete this.addPersonForm.contactNumber;
          if (!this.addPersonForm.address) delete this.addPersonForm.address;

          this.loading = true;
          api
            .addUser(this.addPersonForm)
            .then(
              (res) => {
                if (res.msg === "Email is already exist") {
                  this.$message.error(this.$t("EmailExist"));
                } else if (res.msg === "Email cannot be null") {
                  this.$message.error(this.$t("EmptyEmail"));
                } else if (res.msg === "Mobile is already exist") {
                  this.$message.error(this.$t("TelExist"));
                } else {
                  // 新加人员添加到未分组
                  const param = {
                    systemType: 1, // 1:商会、2:律所
                    chamberId: this.chamberId, // 商会id
                    tenantCode: this.tenantCode,
                  };
                  api.scanNewMember(param).then((respond) => {
                    if (respond.data.code === 200) {
                      this.$message.success(this.$t("AddSuccess"));
                      this.cancelDialog();
                      this.initUserData(1);
                    } else {
                      this.$message.error(this.$t("SysBusy"));
                    }
                  });
                }
              },
              (error) => {
                this.$message.error(this.$t("SysBusy"));
              }
            )
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 关闭添加用户弹窗
    clearAddPerson() {
      this.addPersonForm = {
        companyName: "",
        contactName: "",
        realname: "",
        email: "",
        contactNumber: "",
        address: "",
        password: "",
        pass: "",
        checkPass: "",
        adminId: this.adminId,
        chamberId: this.chamberId,
        messageText: "",
        currentAdmin: this.adminId,
      };
      this.addPersonDialog = false;
    },

    cancelDialog() {
      this.addPersonDialog = false;
      this.addPersonForm = {
        companyName: "",
        contactName: "",
        email: "",
        contactNumber: "",
        address: "",
        password: "",
        pass: "",
        checkPass: "",
        chamberId: this.chamberId,
        messageText: "",
        currentAdmin: this.adminId,
      };
    },

    //            同步编辑用户弹窗
    editUserDialog(tab) {
      // console.log("tab", tab);
      this.editPersonForm.companyName = tab.companyName;
      this.editPersonForm.nickName = tab.nickName;
      this.editPersonForm.realName = tab.realName;
      this.editPersonForm.email = tab.email;
      this.editPersonForm.contactNumber = tab.contactNumber;
      this.editPersonForm.address = tab.address;
      this.editPersonForm.status = tab.status;
      this.editPersonForm.userId = tab.userId;
      //this.editPersonDialog = true;
      // console.log("api连接", this.editPersonForm);
    },

    //编辑用户
    editUserData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          api
            .editUser(this.editPersonForm)
            .then((res) => {
              if (res.msg === "Mobile is already exist") {
                this.$message.error(this.$t("TelExist"));
              } else {
                this.editPersonDialog = false;
                this.initUserData(1);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          // console.log("error submit");
          return false;
        }
      });
    },

    // 启用用户
    initiateUser(row) {
      this.editUserDialog(row);

      this.$confirm(this.$t("ComfirmInit"), this.$t("Attention"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancle"),
        type: "warning",
      })
        .then(() => {
          this.editPersonForm.status = 1;
          api
            .editUser({
              userId: row.userId,
              status: 1,
              currentAdmin: this.adminId,
            })
            .then(() => {
              this.initUserData(1);
            });
        })
        .catch(() => {});
    },

    // 禁用用户
    banUser(row) {
      this.editUserDialog(row);

      this.$confirm(this.$t("ComfirmBan"), this.$t("Attention"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancle"),
        type: "warning",
      })
        .then(() => {
          this.editPersonForm.status = 2;
          api
            .editUser({
              userId: row.userId,
              status: 2,
              currentAdmin: this.adminId,
            })
            .then(() => {
              this.initUserData(1);
            });
        })
        .catch(() => {});
    },

    // 删除用户
    deleteUserData(tab) {
      this.$confirm(this.$t("ComfirmDeleteRow"), this.$t("Attention"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancle"),
        type: "warning",
      })
        .then(() => {
          api
            .deleteUser({
              userId: tab.userId,
              currentAdmin: this.adminId,
            })
            .then(() => {
              this.initUserData(1);
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.importExport {
  width: 99%;
  height: 60px;

  .el-button {
    margin-top: 4px;
    height: 40px;
    line-height: 0.4;
    background-color: #425d7b;
    color: white;

    &:hover {
      background-color: #9fbee6 !important;
    }
  }
}

.import {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.uploadExcel {
  display: flex;
  flex-flow: column nowrap;
}

.mainBody {
  width: 1600px;
  margin-top: 5px;
}

.filterBarUser {
  width: 99%;
  height: 130px;
  margin: 5px auto 10px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  background-color: #677d95;
  border-radius: 5px;
  color: white;
  padding-top: 10px;
  /*line-height: 65px;*/
  input {
    width: 200px;
    height: 30px;
    border: 1px solid white;
    margin: 8px 0;
    vertical-align: middle;
    float: left;
  }

  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
    //width: 80px;
  }

  .el-button {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 4;
    grid-column-end: 5;
    /*margin: 4px 10px 0 120px;*/
    margin: auto;
    width: 200px;
    height: 50px;
    line-height: 0.4;
    /*line-height: 50px;*/
    background-color: #677d95;
    color: white;

    &:hover {
      background-color: #9fbee6 !important;
    }
  }
}

.navigatorUser {
  /*width: 1660px;*/
  width: 99%;
  height: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /*border:1px solid black;*/
  margin: 10px auto;

  & > span {
    padding-top: 3px;
    text-align: center;
    border: 1px solid black;
  }
}

.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}

.active {
  //background-color: #1C252C;
  background-color: #677d95;
  color: white;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>

